/* eslint-disable no-shadow */
import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

import { getExcerpt } from "src/utils/posts"

import "./Card.scss"

const typeSwitch = template => ({
  "work": "Case Study",
  "post": "Article",
  "book": "White Paper",
  "media": "Talk",
})[template] || template

const defaultImageSwitch = template => ({
  "post": "/img/card-default-post.png",
  "book": "/img/card-default-book.png",
  "media": "/img/card-default-talk.png",
  "letter": "/img/card-default-letter.png",
})[template] || ""

const InsightCard = ({index, card: {node: {frontmatter: {meta: {
  image, title, template, description, link, path
}, categories}, html}}}) => {
  const cardType = typeSwitch(template)

  const category = categories ? categories[0] : null

  const cardContent = (
    <div className="insight-card-inner" key={index}>
      <div className="card-image">
        {image 
        ? <GatsbyImage 
            image={image.childImageSharp.gatsbyImageData} 
            alt={title}   
          />
        :
          <img
            className="gatsby-image-wrapper"
            src={`${defaultImageSwitch(template)}`}
            alt={title}
          />
        }
        
      </div>
      <div className="card-body">
        <h6 className="card-type">
          {category &&
            <><span className="category">{category}</span>, </>
          }
          {cardType}
        </h6>
        <h3 className="card-title">{title}</h3>
        <p className="card-text">
          {description &&
            description
          }

          {!description && html &&
            `${getExcerpt({text: html, characters: 600})}...`
          }
        </p>
        <div className="card-fade" />
      </div>
    </div>
  )

  return (
    <>
      {link &&
        <a
          className="insight-card"
          key={title}
          href={link}
          target="_blank"
          rel="noopener noreferrer"
        >
          {cardContent}
        </a>
      }
      {!link && path &&
        <Link
          className="insight-card"
          key={title}
          to={path}
        >
          {cardContent}
        </Link>
      }
    </>
  )
}

export default InsightCard
