import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"

import InsightCard from "src/components/insights/Card"
import Layout from "src/components/new/Layout"
import Header from "src/components/new/Header"
import NewsletterForm from "src/components/forms/Newsletter"

import "./insights.scss"

const InsightsPage = ({data: {page: {insights}}, location}) => {
  const first3 = insights.slice(0, 3)
  const otherInsights = insights.slice(3, insights.length)

  return (
    <Layout bodyClass="gray" location={location} meta={{
      title: "Our insights",
      description: "Insights by Vaporware's experts about product management, design, and development in business, real estate, information technology, human resources, and healthcare.",
    }}>
      <div className="insights-page">
        <Header>
          <h1>From deep technical topics to current business trends, our articles, letters, and talks have you covered.</h1>
        </Header>
        <section className="top-insights">
          <div className="container card-container">
            {first3.map((card, index) => (
              <InsightCard card={card} key={index} lazyLoad={false} />
            ))}
          </div>
        </section>

        <section className="signup">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <p>
                  Every Tuesday, get an insightful conversation delivered straight to your inbox. Dan Moore, our CEO, shares our processes, perspective, industry trends, insights, and more in the world of product design, development, and management.
                  <br /><br />
                  We encourage every subscriber to ask questions, share their perspective, and be connected with our community in the fields of Healthcare, Information Technology, Human Resources, and Real Estate. Need some insight? This is the place for you.
                </p>
              </div>
              <div className="col-md-3 offset-md-1">
                <NewsletterForm />
              </div>
            </div>
          </div>
        </section>

        <section className="other-insights">
          <div className="container card-container">
            {otherInsights.map((card, index) => (
              <InsightCard card={card} key={index} />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  )
}

InsightsPage.defaultProps = {
  data: {
    page: {
      insights: [],
    },
  },
}

InsightsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      insights: PropTypes.arrayOf(PropTypes.object),
    }),
  }),
}

export const pageQuery = graphql`
  query {
    page: allMarkdownRemark(
      filter: { frontmatter: { meta: { template: { in: ["post","letter","media"] }, published: { eq: true }, indexed: { ne: false } } } }
      sort: { fields: frontmatter___meta___date, order: DESC }
    ) {
      insights: edges {
        node {
          ...InsightFragment
        }
      }
    }
  }
`

export default InsightsPage
