import React, { Component } from "react"
import addToSendGrid from "src/lambda/helpers/sendgrid/add-contact-to-list"
import Input from "src/components/forms/Input"
import "./Newsletter.scss"

const NEWSLETTER_LIST = "3c8887a5-a9fe-4e92-9c3a-61db5fc0ae2b"

class NewsletterForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      formData: {
        name: "",
        email: "",
      },
      formErrors: {
        name: "",
        email: "",
      },
      hasSubmitted: false,
      submitSuccess: false,
      submitFailure: false,
      disabled: false,
      emailValid: false,
      formValid: false
    }
  }

  validateFields = () => {
    const { formData, formErrors } = this.state
    const fieldValidationErrors = formErrors

    const emailValid = formData.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)
    fieldValidationErrors.email = emailValid ? "" : "Please enter a valid email"

    this.setState({ 
      formErrors: fieldValidationErrors,
      emailValid: Boolean(emailValid),
    }, () => this.validateForm())

    return emailValid
  }

  validateForm = () => {
    const { emailValid } = this.state
    this.setState({ formValid: emailValid })
  }

  resetForm = () => {
    this.setState({
      formData: {
        name: "",
        email: "",
      },
    })
  }

  handleChange = e => {
    const { formData, hasSubmitted } = this.state
    const { name, value } = e.target
    this.setState({
      formData: {
        ...formData,
        [name]: value
      }
    }, () => { if(hasSubmitted) this.validateFields() })
  }

  handleSubmit = e => {
    e.preventDefault()
    this.setState({ hasSubmitted: true })
    if(this.validateFields()) {
      const form = e.target
      const name = form.name.value
      const email = form.email.value
      addToSendGrid({ name, email, listId: NEWSLETTER_LIST }).then(response => {
        if(response.ok){
          this.setState({ submitFailure: false, submitSuccess: true })
          this.resetForm()
        } else {
          this.setState({ submitSuccess: false, submitFailure: true })
        }
      })
    }
  }

  render() {
    const { 
      disabled, 
      formData, 
      formErrors, 
      hasSubmitted, 
      formValid,
      submitSuccess,
      submitFailure 
    } = this.state
    const { email, name } = formData

    const disabledSubmit = hasSubmitted && !formValid

    return (
      <div className="newsletter-form">
        <form 
          name="newsletter" 
          method="POST" 
          onSubmit={this.handleSubmit}
        >
          <Input
            label="Name"
            name="name"
            onChange={this.handleChange}
            value={name}
            disabled={disabled}
            className="form-control form-control-lg"
            error={formErrors.name}
          />
          <Input
            label="Email"
            type="email"
            onChange={this.handleChange}
            name="email"
            value={email}
            disabled={disabled}
            className="form-control form-control-lg"
            error={formErrors.email}
          />
          <button 
            type="submit" 
            className="btn btn-primary btn-lg"
            disabled={disabledSubmit}
          >
            Sign Up
          </button>
          {submitSuccess && 
            <div className="message success">Check your email.</div>
          }
          {submitFailure && 
            <div className="message error">There was a problem.</div>
          }
        </form>
      </div>
    )
  }
}

export default NewsletterForm
