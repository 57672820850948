/* eslint-disable camelcase */
import fetch from 'node-fetch'

export default ({ name, email, company, listId }) => {
  const contact = { email, first_name: name, custom_fields: {e1_T: company} }
  const body = { list_ids: [listId], contacts: [contact] }
  const url = 'https://api.sendgrid.com/v3/marketing/contacts'
  const options = {
    method: 'PUT',
    headers: { 
      authorization: 'Bearer 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',
      'content-type': 'application/json' 
    },
    body: JSON.stringify(body)
  }
  console.debug("calling sendgrid: ", options)
  return fetch(url, options, (e) => {
    if(e) throw new Error(e)
  })
}